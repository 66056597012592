div.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.login-form {
  width: 500px;
  height: 280px;
  padding: 40px;
  box-shadow: 2px 2px 4px gray;
}

div.logo-area {
  text-align: center;
}

div.login-form img.logo {
  width: 320px;
  height: 100px;
  margin-bottom: 20px;
}

div.login-form-item-format {
  display: flex;
  /* justify-content: center; */
  margin: 0 0 24px !important;
}

div.ant-row.ant-form-item.ant-form-item-with-help {
  margin-bottom: 5px !important;
}

div.login-form-items .ant-form-item-control {
  width: 280px;
}

div.login-form-buttons {
  text-align: center;
}
