.ant-menu-item .footer {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 28px;
}

.ant-menu {
  font-family: sans-serif, "メイリオ";
}
